<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    :is-calculation-enable="true"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'

const sumberDanaOptions = [
  'Perguruan tinggi sendiri',
  'Yayasan',
  'Kementerian',
  'Lembaga tertentu DN/LN',
  'Sumber lain',
]

const jenisDanaOptions = [
  'SPP',
  'UPT',
  'Pendaftaran Mahasiswa Baru',
  'Opspek',
  'UTS',
  'UAS',
  'KKL Jawa',
  'PPL',
  'Baksos',
  'Seminar Proposal',
  'Bimbingan Skripsi',
  'Ujian Skripsi',
  'Yudisium',
  'Wisuda',
  'Gaji Dosen Yayasan',
  'Gaji Dosen DPK (PNS)',
  'Beasiswa PPA',
  'Beasiswa BBP-PPA',
  'Beasiswa Bidikmisi',
]

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_pemdana',  
      headers: [
        [{
          label: 'Sumber Dana',
          dataKey: 'sumber_dana',
          dataAttributes({ vm, index, data }) {
            const sumberDanaIndexes = sumberDanaOptions.map(sumberDana => {
              const sumberDanaIndex = vm.data.findIndex(data => data.sumber_dana == sumberDana)
              const rowspan = vm.data.filter(data => data.sumber_dana == sumberDana).length
              return {
                name: sumberDana,
                rowspan,
                index: sumberDanaIndex >= 0 ? sumberDanaIndex : null
              }
            })
            
            const attributes = { class: 'text-center' }
            const currentSumberDana = sumberDanaIndexes.find(sumberDana => sumberDana.name == data.sumber_dana)
            if (currentSumberDana.index == index) {
              attributes.rowspan = currentSumberDana.rowspan
            } else {
              attributes.hide = true
            }

            return attributes
          },
        }, {
          label: 'Jenis Dana',
          dataKey: 'jenis_dana',
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'TS-2',
          dataKey: 'ts_2',
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'TS-1',
          dataKey: 'ts_1',
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'TS',
          dataKey: 'ts',
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Rata-Rata',
          dataKey: 'rata_rata',
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center' },
        }]
      ],
      form: {
        inputs: [{
          label: 'Sumber Dana',
          name: 'sumber_dana',
          dataKey: 'sumber_dana',
          type: 'select',
          options: sumberDanaOptions,
          rules: 'required',
        }, {
          label: 'Jenis Dana',
          name: 'jenis_dana',
          dataKey: 'jenis_dana',
          type: 'select',
          options: jenisDanaOptions,
          fetchOptions(search) {
            return [search, ...jenisDanaOptions]
          }
        }, {
          label: 'TS-2',
          name: 'ts_2',
          dataKey: 'ts_2',
          type: 'textarea',
        }, {
          label: 'TS-1',
          name: 'ts_1',
          dataKey: 'ts_1',
          type: 'textarea',
        }, {
          label: 'TS',
          name: 'ts',
          dataKey: 'ts',
          type: 'textarea',
        }]
      },
    }
  },
}
</script>